<template>
  <div class="row" style="background-color: #323547; color: white; padding-top:125px">
  </div>
</template>

<script>
export default {
  name: 'home-header',
  components: {},
  data () {
    return {
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
